// import external dependencies
import 'jquery';
// core version + navigation, pagination modules:
import SwiperCore, { Navigation, Pagination, Controller, EffectFade } from 'swiper/core';
import simpleParallax from 'simple-parallax-js';
import plyr from 'plyr';

// configure Swiper to use modules
SwiperCore.use([Navigation, Pagination, Controller, EffectFade]);

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

if (typeof acf != 'undefined') {
  var acfVersion = acf.get('acf_version');
  alert(acfVersion);
}

// Plyr Video

$(function () {
  const player = plyr.setup('.video', {
    controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'fullscreen'],
    autoplay: false,
    muted: false,
    fullscreen: { iosNative: false },
    resetOnEnd: true,
  });

  $('.modal').on('shown.bs.modal',function (){
      if(player === null) {
          return null;
      } else {
          player.forEach(function(player) {
              player.pause();
          });
      }
  });

  window.player = player;
});

//
// Carousels  
//

$(function () {
  
  // Landscape Carousel
  const swiperLandscape = new SwiperCore('.swiper-trigger-landscape', {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    navigation: {
      nextEl: '.carousel-landscape .swiper-button.next',
      prevEl: '.carousel-landscape .swiper-button.previous',
    },
    watchSlidesProgress: true,
  });
  
  // Square Carousel
  const swiperSquare = new SwiperCore('.swiper-trigger-square', {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    navigation: {
      nextEl: '.carousel-square .swiper-button.next',
      prevEl: '.carousel-square .swiper-button.previous',
    },
    watchSlidesProgress: true,
  });
  
  // Portrait Large Carousel
  const swiperPortraitFull = new SwiperCore('.swiper-trigger-portrait-full', {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    navigation: {
      nextEl: '.carousel-portrait-full .swiper-button.next',
      prevEl: '.carousel-portrait-full .swiper-button.previous',
    },
    watchSlidesProgress: true,
  });
  
  // Portrait Carousel
  const swiperPortrait = new SwiperCore('.swiper-trigger-portrait', {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    navigation: {
      nextEl: '.carousel-portrait .swiper-button.next',
      prevEl: '.carousel-portrait .swiper-button.previous',
    },
    watchSlidesProgress: true,
  });

  // Portrait Stacked
  const swiperPortraitStacked = new SwiperCore('.swiper-trigger-portrait-stacked', {
    navigation: {
      nextEl: '.carousel-portrait-stacked .swiper-button.next',
      prevEl: '.carousel-portrait-stacked .swiper-button.previous',
    },
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    breakpoints: {
      640: {
        enabled: false,
        allowSlideNext: false,
        allowSlidePrev: false,
      },
    },
  });

  // Stacked Large
  const swiperStackedLarge = new SwiperCore('.swiper-trigger-stacked-large', {
    navigation: {
      nextEl: '.carousel-stacked-large .swiper-button.next',
      prevEl: '.carousel-stacked-large .swiper-button.previous',
    },
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    breakpoints: {
      768: {
        enabled: false,
        allowSlideNext: false,
        allowSlidePrev: false,
      },
    },
    on: {
      slideChange: function () {
        if (swiperStackedLarge === null) {
          return null;
        } else {
          window.player.forEach(function (plyr) {
            if (plyr.playing) {
              plyr.pause();
            }
          });
        }
      },
    },
  });

  // Full Carousel
  const mainSliderSelector = '.swiper-trigger-full-images',
      textSliderSelector = '.swiper-trigger-full-text';

  // Main Slider
  const mainSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    touchEventsTarget: 'wrapper',
    speed: 500,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button.next',
      prevEl: '.swiper-button.previous',
    },
    pagination: {
      el: '.swiper-pagination-buttons',
      clickable: true,
      renderBullet: function (index, className) {
        var slider_array = [];
        var el = $('.swiper-trigger-full-images')
        el.find('[data-title]').each(function () {
            slider_array.push($(this).data('title'));
        });
        el.find('[data-header]').each(function () {
            slider_array.push($(this).data('header'));
        });

        return '<span class="btn btn-sm btn-underline px-0 my-1 mx-2 mx-md-3 mx-lg-4 w-auto h-auto bg-transparent rounded-0 ' + className + '">' + slider_array[index] + '</span>';
      },
    },
  }
  const mainSlider = new SwiperCore(mainSliderSelector, mainSliderOptions);
  // Navigation Slider
  const textSliderOptions = {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    watchSlidesProgress: true,
    simulateTouch: false,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: '.swiper-pagination-fraction',
      type: 'fraction',
    },
  }
  const textSlider = new SwiperCore(textSliderSelector, textSliderOptions);
  // Matching sliders
  mainSlider.controller.control = textSlider;
  textSlider.controller.control = mainSlider;



  // Filter Carousel
  $('.swiper-filter').on( 'click', 'a', function() {
    const filter = $(this).attr('data-filter');
    
    $('.swiper-trigger-filter .swiper-slide').addClass('d-none');
    $('.swiper-trigger-filter .swiper-slide' + filter).removeClass('d-none');
    $( '.swiper-filter a' ).removeClass( 'swiper-active btn-underline' ).addClass( 'text-muted' );
    $( this ).addClass( 'swiper-active btn-underline' ).removeClass( 'text-muted' );
    
    productSwiper.updateSize();
    productSwiper.updateSlides();
    productSwiper.updateProgress();
    productSwiper.updateSlidesClasses();
    productSwiper.slideTo(0);
    
    return false;
  });

  const filterSwiper = new SwiperCore('.swiper-filter', {
    slidesPerView: 'auto',
    watchOverflow: true,
    speed: 500,
    allowTouchMove: false,
  });
    
  const productSwiper = new SwiperCore('.swiper-trigger-filter', {
    slidesPerView: 'auto',
    watchOverflow: true,
    runCallbacksOnInit: true,
    observer: true,
    navigation: {
      nextEl: '.swiper-button.next',
      prevEl: '.swiper-button.previous',
    },
    updateOnImagesReady: true,
  });
    
});

// Parallax images
$(window).on('load resize', function (event) {
  const w = document.documentElement.clientWidth || window.innerWidth;
  if (w <= 768) {
  // Probably mobile
    const images = document.querySelectorAll('img-parallax');
    const instance = new simpleParallax(images);
    instance.destroy();
  } else {
  // Probably desktop
    const imageOne = document.getElementsByClassName('img-parallax-0');
    new simpleParallax(imageOne, {
      scale: 2,
      overflow: true,
      delay: 1,
    });
    
    const imageTwo = document.getElementsByClassName('img-parallax-1');
    new simpleParallax(imageTwo, {
      scale: 2,
      overflow: true,
      delay: 1,
      orientation: 'down',
    });
  }
});



