export default {
  init() {
    // JavaScript to be fired on all pages

    // Calculate navbar height for banner padding

    $(window).on('load resize', function (event) {
      let $navbar = $('#nav-site');
      let $banner = $('.banner-inner');

      $banner.css({
        paddingTop: $navbar.outerHeight(),
        paddingBottom: $navbar.outerHeight() / 2,
      });
    });

    // Prevent scroll when mobile navbar open 
    $('#navbarCollapse').on('show.bs.collapse', function () {
      $('body').addClass('navbar-open overflow-hidden');
      $('.navbar-primary').addClass('bottom-0');
    });
    $('#navbarCollapse').on('hide.bs.collapse', function () {
      $('body').removeClass('navbar-open overflow-hidden');
      $('.navbar-primary').removeClass('bottom-0');
    });

    // Navbar resize/show/hide
    $(function () {
      let prevScrollpos = $(window).scrollTop();
      let hideNavBarOffsetHeight = $(window).height() * 0.15;
      let styleNavBarOffsetHeight = $(window).height() * 0.3;

      $(window).scroll(function () {

        // When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar
        let currentScrollPos = window.pageYOffset;

        if ($(window).scrollTop() > hideNavBarOffsetHeight) {
          if (prevScrollpos > currentScrollPos) {
            $('#nav-site').removeClass('nav-hide')
            $('.wrap').addClass('wrap-scrolled');
          } else {
            $('#nav-site').addClass('nav-hide');
          }
        }

        prevScrollpos = currentScrollPos;

        // When the user scrolls down from the top of the document, alter navbar's styling
        if ($(window).scrollTop() > styleNavBarOffsetHeight) {
          $('#nav-site').addClass('nav-scrolled').addClass('shadow');
          $('body').removeClass('modal-open');
        } else {
          $('#nav-site').removeClass('nav-scrolled').removeClass('shadow');
          $('body').removeClass('modal-open');
          $('.wrap').removeClass('wrap-scrolled');
        }
      });
    });

    // Contact form inputs
    var contactFormInputs = document.querySelectorAll('.wpcf7-form-control'); // or your custom input class
    contactFormInputs.forEach(function(element) {
      element.addEventListener('focus', function(event) {
        event.target.parentElement.classList.add('focused');
      });
      element.addEventListener('blur', function(event) {
        event.target.parentElement.classList.remove('focused');
      });
      element.addEventListener('change', function (event) {
        event.target.value
          ? event.target.parentElement.classList.add('valid')
          : event.target.parentElement.classList.remove('valid')
      });
    });
        
    // Tabs
    $('#tabs-select').on('change', function (e) {
      $('.tab-pane').removeClass('active show')
      $('#' + $(e.currentTarget).val()).addClass('active show');
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    
  },
};
